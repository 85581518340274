import React from "react";
import styles from "./App.module.scss";
import JournalProcessor, { MissionInfo } from "./JournalProcessor";
import LocalDB from "./LocalDB";
import MissionSection from "./MissionSection";
import { groupBy, keysSortedByValueCountDesc } from "./Utils";

interface State {
  dirHandle: FileSystemDirectoryHandle | null;
  prevDH?: FileSystemDirectoryHandle;
  missions?: readonly MissionInfo[];
  journalProcessor?: JournalProcessor;
}

export default class App extends React.Component<{}, State> {
  public state: State = { dirHandle: null };

  private readonly db = LocalDB.getSingleton();
  private isDestroyed = false;
  private pendingCheckTimeout: NodeJS.Timeout | undefined;

  public render() {
    const { dirHandle: dh, prevDH, missions } = this.state;
    return (
      <div className={styles.root}>
        {dh !== null ? null : (
          <>
            <span className={styles.button} onClick={this.openDir}>
              Open dir
            </span>
            {prevDH === undefined ? null : (
              <>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span className={styles.button} onClick={this.usePrevDir}>
                  Use prev dir
                </span>
              </>
            )}
          </>
        )}
        {missions === undefined ? null : this.renderMissions(missions)}
      </div>
    );
  }

  private renderMissions(missions: readonly MissionInfo[]) {
    const sections: React.ReactNode[] = [];

    const currentSystem = this.state.journalProcessor?.currentSystem;

    const bySystem = groupBy(missions, e => e.destination.system);
    let orderedSystems = keysSortedByValueCountDesc(bySystem);
    if (currentSystem !== undefined) {
      orderedSystems = [
        currentSystem,
        ...orderedSystems.filter(s => s !== currentSystem)
      ];
    }
    for (const system of orderedSystems) {
      const systemMissions = bySystem.get(system) ?? [];
      sections.push(
        <MissionSection
          key={system}
          title={`${system} (${systemMissions.length})`}
          missions={systemMissions}
          isCurrent={system === currentSystem}
        />
      );
    }

    return <div className={styles.missions}>{sections}</div>;
  }

  public componentDidMount() {
    super.componentDidMount?.();
    this.isDestroyed = false;

    // let prevLastModified: number | undefined;
    const doCheck = async () => {
      if (this.isDestroyed) {
        return;
      }

      const jp = this.state.journalProcessor;
      if (jp !== undefined) {
        const somethingChanged = await jp.update();
        if (somethingChanged || this.state.missions === undefined) {
          this.setState({ missions: jp.getActiveMissions() });
        }
      }

      this.pendingCheckTimeout = setTimeout(doCheck, 1000);
    };

    doCheck();

    this.db.getDirHandle("test").then(prevDH => {
      if (prevDH !== undefined) {
        this.setState({ prevDH });
      }
    });
  }

  public componentWillUnmount() {
    this.isDestroyed = true;
    if (this.pendingCheckTimeout) {
      clearTimeout(this.pendingCheckTimeout);
    }
    super.componentWillUnmount?.();
  }

  private readonly openDir = async () => {
    const dh = await window.showDirectoryPicker();
    this.setState({
      dirHandle: dh,
      journalProcessor: new JournalProcessor(dh)
    });
    await this.db.putDirHandle("test", dh);
  };

  private readonly usePrevDir = async () => {
    const { prevDH } = this.state;
    if (prevDH === undefined) {
      return;
    }

    await prevDH.requestPermission();
    this.setState({
      dirHandle: prevDH,
      journalProcessor: new JournalProcessor(prevDH)
    });
  };
}
