type DeferredPromise<T> = Promise<T> & { trigger(): void };
export default DeferredPromise;

export function createDeferredPromise<T>(
  func: () => Promise<T>
): DeferredPromise<T> {
  let myResolve: ((value: Promise<T>) => void) | undefined;
  let wasTriggered = false;
  return Object.assign(
    new Promise<T>(resolve => {
      myResolve = resolve;
    }),
    {
      trigger: async () => {
        if (!wasTriggered) {
          wasTriggered = true;
          myResolve!(func());
        }
      }
    }
  );
}
