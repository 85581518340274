import { Serializable } from "child_process";
import memoize from "lodash/memoize";
import { openDB, IDBPDatabase } from "idb";

export default class LocalDB {
  public static getSingleton = memoize(() => new LocalDB());

  private static DB_NAME = "elitehelper";
  private static DB_VERSION = 1;
  private static STORE_DIRS = "dirs";

  private readonly dbPromise: Promise<IDBPDatabase<unknown>>;

  // singleton only
  private constructor() {
    this.dbPromise = openDB(LocalDB.DB_NAME, LocalDB.DB_VERSION, {
      upgrade: db => {
        db.createObjectStore(LocalDB.STORE_DIRS);
      }
    });
  }

  private async put(storeName: string, key: string, value: Serializable) {
    const db = await this.dbPromise;
    return db.put(storeName, value, key);
  }

  private async get(storeName: string, key: string) {
    const db = await this.dbPromise;
    return db.get(storeName, key);
  }

  public putDirHandle(key: string, value: FileSystemDirectoryHandle) {
    return this.put(LocalDB.STORE_DIRS, key, value);
  }

  public getDirHandle(
    key: string
  ): Promise<FileSystemDirectoryHandle | undefined> {
    return this.get(LocalDB.STORE_DIRS, key);
  }
}
