import React from "react";
import { MissionInfo, StationType } from "./JournalProcessor";
import styles from "./MissionSection.module.scss";
import {
  cm,
  groupBy,
  keysSortedByValueCountDesc,
  readonlyRecordOf
} from "./Utils";

// mission icons
import CharityMissionIcon from "./assets/missions/charity.svg";
import CourierMissionIcon from "./assets/missions/courier.svg";
import DeliveryMissionIcon from "./assets/missions/delivery.svg";
import UnknownMissionIcon from "./assets/missions/unknown-mission.svg";

// station type icons
import SettlementIcon from "./assets/stations/surface_port_sm.svg";
import StationIcon from "./assets/stations/coriolis_sm.svg";

export interface Props {
  title: string;
  missions: MissionInfo[];
  isCurrent?: boolean;
}

interface MissionTypeConfig {
  icon: string;
}

const missionTypeConfigs = readonlyRecordOf<MissionTypeConfig>()({
  charity: {
    icon: CharityMissionIcon
  },
  courier: {
    icon: CourierMissionIcon
  },
  delivery: {
    icon: DeliveryMissionIcon
  },
  unknown: {
    icon: UnknownMissionIcon
  }
});

type MissionType = keyof typeof missionTypeConfigs;

const stationTypeIcons: Readonly<Record<StationType, string>> = {
  settlement: SettlementIcon,
  station: StationIcon
};

const MissionTypeIcon = (props: {
  missionType: MissionType;
  tooltip?: string;
  count: number;
}) => {
  const { missionType, count } = props;
  return (
    <>
      <img
        className={styles.missionTypeIcon}
        alt={missionType}
        src={missionTypeConfigs[missionType].icon}
        title={props.tooltip}
      />
      {count === 1 ? undefined : (
        <span className={styles.missionCount}>{props.count}</span>
      )}
    </>
  );
};

function getMissionType(mi: MissionInfo): MissionType {
  if (mi.name.match(/^Mission_AltruismCredits(_.*)?$/)) {
    return "charity";
  } else if (mi.name.match(/^Mission_Courier(_.*)?$/)) {
    return "courier";
  } else if (mi.name.match(/^Mission_Delivery(_.*)?$/)) {
    return "delivery";
  } else {
    console.log("unrecognized name", mi.name);
    return "unknown";
  }
}

export default class MissionSection extends React.Component<Props> {
  public render() {
    const items: React.ReactNode[] = [];
    const byStation = groupBy(
      this.props.missions,
      e => e.destination.station ?? `[${e.displayName}]`
    );
    for (const station of keysSortedByValueCountDesc(byStation)) {
      const stationMissions = byStation.get(station)!;
      const stationType =
        stationMissions.length === 0
          ? undefined
          : stationMissions[0].destination.stationType;
      const groupedMissions = groupBy(stationMissions, getMissionType);
      items.push(
        <li key={station}>
          {stationType === undefined ? undefined : (
            <img
              className={styles.stationTypeIcon}
              src={stationTypeIcons[stationType]}
              alt={stationType}
            />
          )}
          {station}{" "}
          {keysSortedByValueCountDesc(groupedMissions).map(missionType => {
            const missions = groupedMissions.get(missionType)!;
            return (
              <MissionTypeIcon
                key={missionType}
                missionType={missionType}
                tooltip={missions
                  .map(
                    mission => `${mission.missionID}: ${mission.displayName}`
                  )
                  .join("\n")}
                count={missions.length}
              />
            );
          })}
        </li>
      );
    }

    return (
      <div className={cm(styles, "root", { current: this.props.isCurrent })}>
        <header>{this.props.title}</header>
        <ul>{items}</ul>
      </div>
    );
  }
}
