import { forOwn } from "lodash";

export async function asyncIteratorToArray<T>(
  it: AsyncIterableIterator<T>
): Promise<T[]> {
  const results: T[] = [];
  let i: IteratorResult<T, unknown>;
  while (!(i = await it.next()).done) {
    results.push(i.value);
  }
  return results;
}

export function groupBy<T, G>(
  items: Iterable<T>,
  grouper: (item: T) => G
): Map<G, T[]> {
  const result = new Map<G, T[]>();

  for (const item of items) {
    const group = grouper(item);
    let groupItems = result.get(group);
    if (groupItems === undefined) {
      groupItems = [];
      result.set(group, groupItems);
    }
    groupItems.push(item);
  }

  return result;
}

export function keysSortedByValueCountDesc<K>(
  map: ReadonlyMap<K, readonly unknown[]>
): K[] {
  const result = [...map.keys()];
  result.sort((a, b) => map.get(b)!.length - map.get(a)!.length);
  return result;
}

export function cx(
  ...args: ReadonlyArray<
    string | null | undefined | Record<string, boolean | undefined>
  >
) {
  const results: string[] = [];
  for (const arg of args) {
    if (typeof arg === "string") {
      results.push(arg);
    } else if (arg !== null && arg !== undefined) {
      for (const entry in arg) {
        if (arg[entry]) {
          results.push(entry);
        }
      }
    }
  }
  return results.join(" ");
}

export type CMArg<CS extends Record<string, string>> =
  | keyof CS
  | Partial<Record<keyof CS, boolean>>;

export function cm<CS extends Record<string, string>>(
  classes: CS,
  ...args: ReadonlyArray<CMArg<CS>>
): string {
  const resultParts: string[] = [];
  for (const arg of args) {
    if (typeof arg === "string") {
      resultParts.push(classes[arg]);
    } else {
      forOwn(arg as Partial<Record<keyof CS, boolean>>, (value, key) => {
        if (value) {
          resultParts.push(classes[key]);
        }
      });
    }
  }
  return resultParts.join(" ");
}

export function readonlyRecordOf<T>() {
  return <R extends Record<any, T>>(obj: R): Readonly<R> => obj;
}
