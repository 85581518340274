import DeferredPromise, { createDeferredPromise } from "./DeferredPromise";
import { asyncIteratorToArray } from "./Utils";

export type StationType = "station" | "settlement";

export interface MissionInfo {
  missionID: number;
  name: string;
  displayName: string;
  destination: {
    system: string;
    station?: string;
    stationType?: StationType;
  };
}

export default class JournalProcessor {
  private readonly activeMissions = new Map<number, MissionInfo>();
  private readonly rootDir: FileSystemDirectoryHandle;
  private readonly stationTypes = new Map<string, StationType>();

  private latestEntryTime: number = 0;
  private latestFileTime: number = 0;

  private isUpdating = false;
  private pendingUpdatePromise: DeferredPromise<boolean> | undefined;

  private _latestSystem: string | undefined;

  public constructor(rootDir: FileSystemDirectoryHandle) {
    this.rootDir = rootDir;
    this.isUpdating = true;
    this.doUpdateInternal(/*fromScratch=*/ true);
  }

  public update() {
    if (!this.isUpdating) {
      // isn't currently updating; go for it
      this.isUpdating = true;
      return this.doUpdateInternal(/*fromScratch=*/ false);
    } else if (this.pendingUpdatePromise === undefined) {
      // is currently updating, but we're the first one waiting
      this.pendingUpdatePromise = createDeferredPromise(() =>
        this.doUpdateInternal(/*fromScratch=*/ false)
      );
      return this.pendingUpdatePromise;
    } else {
      // there's another one waiting; piggyback on it
      return this.pendingUpdatePromise;
    }
  }

  public get currentSystem() {
    return this._latestSystem;
  }

  private async getJournalFiles() {
    const entries = await asyncIteratorToArray(this.rootDir.entries());
    const promises = entries
      .filter(
        ([name, h]) => h.kind === "file" && name.match(/^Journal\..*log$/)
      )
      .map(([_, h]) => (h as FileSystemFileHandle).getFile());
    return Promise.all(promises);
  }

  private async doUpdateInternal(fromScratch: boolean) {
    try {
      // make sure the caller did the right thing already
      if (!this.isUpdating) {
        throw new Error("isUpdating should be true here");
      }

      if (fromScratch) {
        this.reset();
      }

      // get all journal files newer than the latest update, sorted by time
      const journalFiles = await this.getJournalFiles();
      const filteredJournalFiles = journalFiles.filter(
        f => f.lastModified >= this.latestFileTime
      );
      filteredJournalFiles.sort((a, b) => a.lastModified - b.lastModified);

      let somethingChanged = false;
      for (const f of filteredJournalFiles) {
        if (await this.processFile(f)) {
          somethingChanged = true;
        }
      }

      // now that everything has completed, update `latestFileTime`
      if (journalFiles.length > 0) {
        this.latestFileTime =
          journalFiles[journalFiles.length - 1].lastModified;
      }

      if (somethingChanged) {
        this.cachedActiveMissions = undefined;
      }

      return somethingChanged;
    } finally {
      const p = this.pendingUpdatePromise;
      if (p !== undefined) {
        // there's a pending update behind this one; let it run now
        this.pendingUpdatePromise = undefined;
        p.trigger();
      } else {
        // there's no pending update; we're done updating
        this.isUpdating = false;
      }
    }
  }

  private cachedActiveMissions: readonly MissionInfo[] | undefined;
  public getActiveMissions(): readonly MissionInfo[] {
    if (this.cachedActiveMissions === undefined) {
      this.cachedActiveMissions = [...this.activeMissions.values()];

      // update station types
      this.cachedActiveMissions.forEach(mi => {
        let stationType: StationType | undefined;
        if (mi.destination.station !== undefined) {
          stationType = this.stationTypes.get(
            this.createSystemStationKey(
              mi.destination.system,
              mi.destination.station
            )
          );
        }
        if (stationType === undefined) {
          delete mi.destination.stationType;
        } else {
          mi.destination.stationType = stationType;
        }
      });
    }
    return this.cachedActiveMissions;
  }

  private reset() {
    this.activeMissions.clear();
    this.latestEntryTime = 0;
    this.latestFileTime = 0;
  }

  private async processFile(file: File) {
    const lines = (await file.text()).split(/[\n\r]+/).filter(v => v !== "");

    let somethingChanged = false;
    for (const line of lines) {
      if (await this.processLine(line)) {
        somethingChanged = true;
      }
    }
    return somethingChanged;
  }

  private async processLine(jsonEvent: string) {
    let event: any;

    try {
      event = JSON.parse(jsonEvent);
    } catch (_) {
      return false;
    }

    // see if this is a new entry vs in the past; ignore if past
    const time = new Date(event["timestamp"]).getTime();
    if (time < this.latestEntryTime) {
      return;
    }
    this.latestEntryTime = time;

    switch (event["event"]) {
      case "Missions":
        return this.processMissions(event);

      case "MissionAccepted":
        return this.processMissionAccepted(event);

      case "MissionRedirected":
        return this.processMissionRedirected(event);

      case "MissionAbandoned":
      case "MissionFailed":
      case "MissionCompleted":
        return this.processMissionEnded(event);

      case "Location":
      case "FSDJump":
        return this.processNewStarSystem(event);

      case "FSSSignalDiscovered":
        return this.processFSSSignalDiscovered(event);

      case "ApproachSettlement":
        return this.processApproachSettlement(event);

      default:
        return false;
    }
  }

  private processMissions(event: { Active: Array<{ MissionID: number }> }) {
    const activeMissionIDs = new Set(event.Active.map(e => e.MissionID));
    const staleMissionIDs = [...this.activeMissions.keys()].filter(
      id => !activeMissionIDs.has(id)
    );
    if (staleMissionIDs.length === 0) {
      return false;
    } else {
      for (const id of staleMissionIDs) {
        this.activeMissions.delete(id);
      }
      return true;
    }
  }

  private processMissionAccepted(event: {
    MissionID: number;
    Name: string;
    LocalisedName: string;
    DestinationSystem?: string;
    DestinationStation?: string;
  }) {
    const missionID = event.MissionID;
    this.activeMissions.set(missionID, {
      missionID,
      name: event.Name,
      displayName: event.LocalisedName,
      destination: {
        system: event.DestinationSystem ?? "(unknown)",
        station: event.DestinationStation
      }
    });
    return true;
  }

  private processMissionEnded(event: { MissionID: number }) {
    return this.activeMissions.delete(event.MissionID);
  }

  private processMissionRedirected(event: {
    MissionID: number;
    NewDestinationSystem: string;
    NewDestinationStation: string;
  }) {
    const missionID = event["MissionID"];
    const missionInfo = this.activeMissions.get(missionID);
    if (missionInfo !== undefined) {
      missionInfo.destination.system = event.NewDestinationSystem;
      missionInfo.destination.station = event.NewDestinationStation;
      return true;
    } else {
      return false;
    }
  }

  private processNewStarSystem(event: { StarSystem?: string }) {
    const system = event.StarSystem;
    if (system) {
      this._latestSystem = system;
      return true;
    }
    return false;
  }

  private processFSSSignalDiscovered(event: {
    SignalName: string;
    IsStation: boolean;
  }) {
    if (event.IsStation) {
      this.setStationTypeInCurrentSystem(event.SignalName, "station");
    }
  }

  private processApproachSettlement(event: { Name: string }) {
    this.setStationTypeInCurrentSystem(event.Name, "settlement");
  }

  private setStationTypeInCurrentSystem(
    name: string,
    stationType: StationType
  ) {
    if (this._latestSystem !== undefined) {
      this.stationTypes.set(
        this.createSystemStationKey(this._latestSystem, name),
        stationType
      );
    }
  }

  private createSystemStationKey(system: string, station: string) {
    return `${system}|${station}`;
  }
}
